import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { NgModule } from '@angular/core';

const APP_ROUTES: Routes = [
	{
		path: '',
		redirectTo: 'ciot',
		pathMatch: 'full'
	},
	
	{
		path: 'ciot',
		loadChildren: () => import('./pages/ciot/ciot.module').then(m => m.CiotModule),
	},
];

@NgModule({
	imports: [RouterModule.forRoot(APP_ROUTES, { preloadingStrategy: PreloadAllModules })],
	exports: [RouterModule]
})
export class AppRoutingModule { }
