import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxCurrencyModule } from 'ngx-currency';
import { NgxMaskModule } from 'ngx-mask';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MAT_DATE_LOCALE } from '@angular/material';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DatePipe, CurrencyPipe, HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { LOCALE_ID } from '@angular/core';
import localePt from '@angular/common/locales/pt';
import { InputErrorModule } from "./shared/input-error/input-error.module";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
registerLocaleData(localePt);

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		NgxMaskModule.forRoot(),
		HttpClientModule,
		NgxCurrencyModule,
		ReactiveFormsModule,
		FormsModule,
        InputErrorModule,
	],
	providers: [
		DatePipe,
		CurrencyPipe,
		{ provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
		{ provide: LOCALE_ID, useValue: 'pt-BR' },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
		{ provide: LocationStrategy, useClass: HashLocationStrategy }
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
