import { Routes } from '@angular/router';
const ɵ0 = () => import("./pages/ciot/ciot.module.ngfactory").then(m => m.CiotModuleNgFactory);
const APP_ROUTES = [
    {
        path: '',
        redirectTo: 'ciot',
        pathMatch: 'full'
    },
    {
        path: 'ciot',
        loadChildren: ɵ0,
    },
];
export class AppRoutingModule {
}
export { ɵ0 };
