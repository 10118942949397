import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
	constructor(private router: Router) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(catchError(
			(err) => {
				let message = 'Falha na comunicação';

				if (err && err.message) {
					message = err.message;
				}
				if (err && err.error && err.error.message) {
					message = err.error.message;
				}

				Swal.fire({
					title: 'Não foi possivel realizar esta operação',
					html: message,
					type: 'error'
				});

				return throwError(err);
			}
		))
	}
}
